export default [{
  header: 'notificationsGestor',
},

{
  title: 'Notifications',
  route: 'notifications',
  icon: 'BellIcon',
},
]
