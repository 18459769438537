export default [{
  header: 'Shop',
},
{
  title: 'Category',
  route: 'category-shop',
  // icon: 'ShoppingCartIcon',
  icon: 'ShoppingCartIcon',
},
{
  title: 'Sellers',
  route: 'vendor',
  // icon: 'ShoppingCartIcon',
  icon: 'ShoppingBagIcon',
},
{
  title: 'Orders',
  route: 'orders',
  // icon: 'ShoppingCartIcon',
  icon: 'CreditCardIcon',
},
{
  title: 'Refunds',
  route: 'refunds',
  // disabled:false,
  // icon: 'ShoppingCartIcon',
  icon: 'TruckIcon',
},
{
  title: 'Payments',
  route: 'payments',
  // icon: 'ShoppingCartIcon',
  icon: 'DollarSignIcon',
},
]
