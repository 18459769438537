export default [{
  header: 'Fast Channels',
},
{
  title: 'fastChannels',
  route: 'fast-channels',
  icon: 'TvIcon',
},

]
