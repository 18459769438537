export default [{
  header: 'Usuarios',
},
{
  title: 'Administrators',
  route: 'administrators-list',
  icon: 'UserCheckIcon',
},
{
  title: 'finalUsers.title',
  route: 'final-users-list',
  icon: 'UsersIcon',
},
]
