<template>
  <ul>
    <component :is="resolveNavItemComponent(item)" v-for="item in optMen" :key="item.header || item.title"
      :item="item" />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from "@core/layouts/utils";
import { provide, ref } from "@vue/composition-api";
import VerticalNavMenuHeader from "../vertical-nav-menu-header";
import VerticalNavMenuLink from "../vertical-nav-menu-link/VerticalNavMenuLink.vue";
import VerticalNavMenuGroup from "../vertical-nav-menu-group/VerticalNavMenuGroup.vue";
import dashboardCountly from "/src/navigation/vertical/dashboardCountly";
import dashboard from "/src/navigation/vertical/dashboard";
import platform from "/src/navigation/vertical/platform";
import designer from "/src/navigation/vertical/designer";
import notificationsGestor from "/src/navigation/vertical/notificationsGestor";
import metrics from "/src/navigation/vertical/metrics";
import metricsCountly from "/src/navigation/vertical/metricsCountly";
import users from "/src/navigation/vertical/users";
import avatar from "/src/navigation/vertical/avatar";
import superuser from "/src/navigation/vertical/superuser";
import contents from "/src/navigation/vertical/contents";
import fastChannels from "/src/navigation/vertical/fastChannels";
import contentsSimple from "/src/navigation/vertical/contentsSimple";
import sponsor from "/src/navigation/vertical/sponsor";
import shop from "/src/navigation/vertical/shop";
import vendor from "/src/navigation/vertical/vendor";
import slider from "/src/navigation/vertical/slider";
import advertising from "/src/navigation/vertical/advertising";
import * as constants from "@core/utils/constants";
import { getUserData } from "@/auth/utils";
import { isEmpty } from "@/store/functions";

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup
  },
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      optMen: []
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
    this.itemsMenu();

  },

  methods: {
    handleResize() {
      /* console.log(this.$store.getters['app/currentBreakPoint'])
      function getMediaQuery() {
        const screenWidth = window.innerWidth
        console.log(screenWidth)
        if (screenWidth < 576) {
          return 'small' // Pantallas pequeñas
        } if (screenWidth >= 576 && screenWidth < 992) {
          return 'medium' // Pantallas medianas
        }
        return 'large' // Pantallas grandes
      }
      const currentMediaQuery = getMediaQuery()
      console.log('Media query actual:', currentMediaQuery) */
      this.$store.commit('app/SET_SIZE_WINDOW', window.innerWidth)
    },
    itemsMenu() {
      try {
        const userData = getUserData()
        let array = [];
        let isShop = false
        let hasProfiles = false
        let isSuperuser = false
        let isFastChannel = true
        let isAds = false
        let hasReels = false
        let isPromotionalCodes = false
        let hasMyChannel = false
        let geoipbloking = false
        let isCountlyActive = false
        const dashboardSimple = {
          title: 'Dashboard',
          icon: 'HomeIcon',
          route: 'dashboard',
        }

        isPromotionalCodes = userData?.profile?.client?.isPromotionalCodes
        geoipbloking = userData?.profile?.client?.geoipbloking
        hasMyChannel = userData?.profile?.client?.hasMyChannel
        hasProfiles = userData?.profile?.client?.hasProfiles
        isCountlyActive = userData?.profile?.client?.isCountlyActive
        isFastChannel = userData?.profile?.client?.isFastChannel

        isShop = userData?.profile?.client?.isShop
        isAds = userData?.profile?.client?.isAds
        isSuperuser = userData?.isSuperuser


        hasReels = userData?.profile?.client?.hasReels
        switch (userData.groups.edges[0].node.name) {
          case constants.publicadorCont:
            array.push(dashboardSimple);
            break;

          case constants.dis:
            array.push(dashboardSimple, ...designer, ...avatar);

            break;
          case constants.editorCont:
            array.push(dashboardSimple, ...contents, ...slider);

            break;
          case constants.gestorUser:
            array.push(dashboardSimple, ...users);
            break;
          case constants.gestorStatis:
            array.push(dashboardSimple);
            isCountlyActive ? array.push(...metricsCountly) : array.push(...metrics);
            break;
          case constants.publi:
            array.push(dashboardSimple, ...advertising);
            break;
          case constants.sponsor:
            array.push(dashboardSimple, ...sponsor, ...advertising);
            break;
          case constants.vendor:
            if (isShop)
              isEmpty(userData.profile.profileVendor.edges) ?
                array.push(dashboardSimple, ...shop) :
                array.push(dashboardSimple, ...vendor);
            else
              array.push(dashboardSimple);
            break;
          case constants.gestorNotifications:
            array.push(dashboardSimple, ...notificationsGestor);
            break;
          case constants.administrador:
          default:
            let menu = []
            // Superusuario
            isSuperuser ? menu.push(...superuser) : ''
            
            // Dashboard metrics
            isCountlyActive ? menu.push(...dashboardCountly) : menu.push(...dashboard)
            
            // Reels
            hasReels ? menu.push(...contents) : menu.push(...contentsSimple)

            // FastChannel
            isFastChannel ? menu.push(...fastChannels) : ''

            // Tienda
            isShop ? menu.push(...shop) : ''

            // Anuncios
            isAds ? menu.push(...advertising) : ''

            // Perfiles
            hasProfiles ? menu.push(...avatar) : ''

            // Usuarios & plataforma base
            menu.push(...users, ...platform)


            // My channel
            hasMyChannel ? menu.push({
              title: 'myChannel',
              route: 'myChannel',
              icon: 'TvIcon',
            })
              : ''

            // Geobloqueo
            geoipbloking ?
              menu.push({
                title: 'Zones',
                route: 'zones',
                icon: 'MapPinIcon',
              }) : ''

            // Códigos promocionales
            isPromotionalCodes ?
              menu.push({
                title: 'Códigos promocionales',
                icon: 'DollarSignIcon',

                children: [
                  {
                    title: 'codes',
                    route: 'promotional-codes',

                  },
                  {
                    title: 'uses',
                    route: 'use-codes',

                  },
                ],
              }) : ''

            // Suscripciones
            menu.push({
              title: 'Purchases',
              route: 'purchases',
              icon: 'DollarSignIcon',
            },
              {
                title: 'Subscriptions',
                route: 'subscriptions',
                icon: 'DollarSignIcon',
              })

            array.push(...menu)

            break;
        }
        /* Vue.config.devtools
          ? array.push(
              ...uiElements,
              ...formAndTable,
              ...chartsAndMaps,
              ...others
            )
          : ""; */

        this.optMen = array.slice();
      } catch (error) {
        console.log(error)

      }
    },

  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  setup() {
    provide("openGroups", ref([]));

    return {
      resolveNavItemComponent
    };
  }
};
</script>
