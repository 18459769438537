export default [{
  header: 'Diseñador',
},

{
  title: 'Branding',
  route: 'branding',
  icon: 'PenToolIcon',
},

{
  title: 'Slider',
  route: 'slider',
  icon: 'PenToolIcon',
},

]
