export default [{
  header: 'Avatars',
},
{
  title: 'Categorias',
  route: 'avatar-category',
  icon: 'FolderIcon',
},
{
  title: 'Avatares',
  route: 'profile-avatar',
  icon: 'UsersIcon',
},

{
  title: 'Ajustes',
  route: 'settings-avatar',
  icon: 'ToolIcon',
},
]
