// import Vue from 'vue'
import { getUserData } from '@/auth/utils'
import dashboard from './dashboard'
import platform from './platform'
import users from './users'
import contents from './contents'
import advertising from './advertising'

const array = []

try {
  const userData = getUserData()

  switch (userData.groups.edges[0].node.name) {
    case 'Publicador de contenidos':
      array.push(...dashboard)

      break
    case 'Editor de contenidos':
      array.push(...dashboard, ...contents)

      break
    case 'Gestor de usuarios':
      array.push(...dashboard, ...users)

      break
    case 'Diseñador':
      array.push(...dashboard, ...platform)

      break
    case 'Publicidad':
      array.push(...advertising)

      break

    default:
      array.push(...dashboard, ...contents, ...users, ...advertising, ...platform)

      break
  }
  // Vue.config.devtools ? array.push(...uiElements, ...formAndTable, ...chartsAndMaps, ...others) : ''
} catch (error) {
  // console.log(error);

}
export default array
