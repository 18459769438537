export default [{
  header: 'Contents',
},
{
  title: 'Categories',
  route: 'contents-categories',
  icon: 'TagIcon',
},
{
  title: 'Podcast',
  route: 'categories-podcast',
  icon: 'MicIcon',
},
{
  title: 'Live',
  route: 'contents-live',
  icon: 'RadioIcon',
},
{
  title: 'Resources',
  route: 'contents-resources',
  icon: 'FilmIcon',
},
{
  title: 'Media',
  route: 'contents-media',
  icon: 'PenToolIcon',
},

{
  title: 'EPG',
  route: 'contents-epg',
  icon: 'TvIcon',
},
]
