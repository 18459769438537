import { getUserData } from '@/auth/utils'

let name = 'Vendedor'
try {
  name = getUserData().profile.profileVendor.edges[0].node.vendorName
// eslint-disable-next-line no-empty
} catch (error) {
}
export default [{
  header: 'Shop',
},
{
  title: name,
  route: 'vendor-category',
  icon: 'ShoppingBagIcon',
},

{
  title: 'Orders',
  route: 'vendor-orders',
  icon: 'ShoppingCartIcon',
},
{
  title: 'Refunds',
  route: 'vendor-refunds',
  icon: 'CreditCardIcon',
},
{
  title: 'Payments',
  route: 'vendor-payments',
  icon: 'DollarSignIcon',
},
]
