export default [{
  header: 'Plataforma',
},
{
  title: 'Settings',
  route: 'settings',
  icon: 'SettingsIcon',
},

{
  title: 'Branding',
  route: 'branding',
  icon: 'PenToolIcon',
}, {
  title: 'Menu',
  route: 'menu',
  icon: 'MenuIcon',
},
{
  title: 'Notifications',
  route: 'notifications',
  icon: 'BellIcon',
},

{
  title: 'Slider',
  route: 'slider',
  icon: 'PenToolIcon',
},
]
