export default [{
  header: 'Contenidos',
},
{
  title: 'Categories',
  route: 'contents-categories',
  icon: 'TagIcon',
},
{
  title: 'Resources',
  route: 'contents-resources',
  icon: 'FilmIcon',
},
{
  title: 'Media',
  route: 'contents-media',
  icon: 'PenToolIcon',
},
]
